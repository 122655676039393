import { jwtDecode } from "jwt-decode";

export const isTokenValid = (token: string | null): boolean => {
  if (!token) return false;

  try {
    const decodedToken: { exp: number } = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
    return expirationTime > Date.now(); // Check if the token is still valid
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
};