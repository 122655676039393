import React, { FC, useEffect } from 'react';
import './Main.scss';
import Sidebar from '../../layout/Sidebar/Sidebar';
import Header from '../../layout/Header/Header';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { RootState } from '../../reducers';
import { setCompany } from '../../reducers/company.slice';
import Loader from '../../components/Loader/Loader';
import { getCompany } from '../../api/company.api';
import Toast from '../../components/Toast/Toast';
import { showToast } from '../../reducers/toast.slice';
import UploadProposal from '../UploadProposal/UploadProposal.lazy';
import { show } from '../../reducers/proposal.slice';


interface MainProps { }

const Main: FC<MainProps> = () => {
  const loader = useSelector((state: RootState) => state.loader);
  const toast = useSelector((state: RootState) => state.toast);
  const showProposal = useSelector((state: RootState) => state.proposal.show);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    try {
      const company = await getCompany();
      if (company) {
        dispatch(setCompany(company));
      }
    } catch (e) {
      dispatch(showToast({ msg: "Company configuration could not be loaded", type: "error" }));
    }
  };
  const onClose = (reason: string, result: any) => {
    dispatch(show(false));
    // reason: 'dismiss' when user closes the modal
    // reason: '' when user submits 
    if (!reason && result) {
      navigate(`/chat/${result.rfp_id}`);
    }
  };

  return (
    <div className="main-wrapper d-flex">
      <Sidebar />
      <div className='content-wrapper flex-grow-1 d-flex flex-column'>
        <Header />
        <div className='content container-fluid'>
          <Toast show={toast.show} type={toast.type} msg={toast.msg} />
          <Outlet />
          {loader.loading > 0 && <Loader />}
          <UploadProposal show={showProposal} onClose={onClose}></UploadProposal>
        </div>
      </div>
    </div>
  )
};

export default Main;
