interface AppConfig {
    apiUrl: string;
    authApiURL: string;
    authenticationUrl: string;
}

const config: AppConfig = {
    apiUrl: 'https://bidgenie.dev.automagic.tools/api/v1',
    authApiURL: 'https://auth-be.dev.automagic.tools/api/v1',
    authenticationUrl: ' https://auth.dev.automagic.tools/realms/bidgenie-dev/protocol/openid-connect/token'
};

export default config;