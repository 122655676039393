import React, { FC } from 'react';
import Lottie from 'react-lottie';
import './Loader.scss';
import * as animationData from './Loader.animation.json';

interface LoaderProps { }

const Loader: FC<LoaderProps> = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className='loader-wrapper'>
      <Lottie options={defaultOptions}
        height={55}
        width={160}/>
      <span className='disclaimer'>This may take a few moments</span>
    </div>
  )
};

export default Loader;
