import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config.dev';
import { AppThunk } from '../store';
import { isTokenValid } from '../utils';
import { jwtDecode } from "jwt-decode";
import { UserIPI } from '../models/user.model';

export interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
  refreshToken: string | null;
  user: UserIPI | null;
}

// const initialState: AuthState = {
//   isAuthenticated: !!localStorage.getItem('token'),
//   token: localStorage.getItem('token') || null,
//   refreshToken: localStorage.getItem('refreshToken') || null,
// };

const initialState: AuthState = {
  isAuthenticated: false,
  token: null,
  refreshToken: null,
  user: null
};

interface LoginPayload {
  access_token: string;
  refresh_token: string;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<LoginPayload>) {
      const { access_token, refresh_token } = action.payload;
      const decodedToken = jwtDecode<UserIPI>(access_token as string);
      state.user = {
        id: decodedToken.sub as string,
        company_id: decodedToken.company_id,
        name: decodedToken.name,
        given_name: decodedToken.given_name,
        family_name: decodedToken.family_name,
        email: decodedToken.email
      };
      state.isAuthenticated = true;
      state.token = access_token;
      state.refreshToken = refresh_token;
      localStorage.setItem('token', access_token);
      localStorage.setItem('refreshToken', refresh_token);
    },
    logout(state) {
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    },
  },
});

export const { loginSuccess, logout } = authSlice.actions;

export const login = (username: string, password: string): AppThunk => async dispatch => {
  const formData = new URLSearchParams();
  formData.append('username', username);
  formData.append('password', password);
  formData.append('client_id', 'bidgenie-app');
  formData.append('client_secret', 'vYLdm7ds9MBYoPC4Vl12YP9P97fiXUks');
  formData.append('grant_type', 'password');

  try {
    const response = await axios.post(`${config.authenticationUrl}`, formData.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    });
    dispatch(loginSuccess(response.data));

  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};



export const initializeAuth = (): AppThunk => dispatch => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  if (isTokenValid(token)) {
    dispatch(loginSuccess({ access_token: token as string, refresh_token: refreshToken as string }));
  } else {
    dispatch(logout());
  }
};

export default authSlice.reducer;